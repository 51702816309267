.container {
    display: flex;
    background-color: pink;
    box-shadow: inset 0 0 20px black;
    width: 100%;
  }
  
  .hero {
    --gap: 20px;
    --num-cols: 4 ;
    --row-height: 400px;

    box-sizing:border-box;
    padding: 60px;
    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
    width: 90%;
    margin: 0 auto;
  }
  
  .hero > img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px black;
    cursor: pointer;
  }
  .hero > img:hover {
    background: linear-gradient(180deg,white,white)50%;
  }
  .hero-grid-col-2 {
    grid-column: span 2;
  }
  
  .hero-grid-row-2 {
    grid-row: span 2;
  }
  
  /* New Styles for Text Overlay */
  .image-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px black;
  }
  
  .image-wrapper img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .image-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: #ff66c4;
    font-size: 1.3em;
    font-weight: bold;
    font-family: Kaushan Script;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.705);
    background: rgba(0, 0, 0, 0.5);
    padding: 30px 0px;
    border-radius: 5px;
  }
@media (width > 2100px) {
    .hero{
        width: 80%;
    }
}
@media screen and (max-width:1450px) {
    .hero{
        width: 90%;
        --row-height:325px
    }
}
@media screen and (max-width: 1150px) {
    .hero{
        width: 100%;
    }
}
@media screen and (max-width: 1024px) {
    .hero {
        --num-cols: 2;
        width: 90%;
    }
}
@media screen and (max-width:770px) {
    .hero{
        margin-top: 50px;
        padding: 40px 20px;
        --row-height:250px;
    }
    .image-text{
        padding: 15px 0;
        font-weight: 500;
    }
}
@media (max-width:500px){
    .hero{
        margin-top: 10px;
        width: 100%;
        --gap:3px;
        --row-height:200px;
        padding: 40px 0 0px 0px;
    }
    .image-wrapper {
        border-radius: 0px;
        box-shadow: 0 0 3px #ff66c4;
    }
    .image-text{
        border-radius: 0px;
    }
    .hero > img{
        border-radius: 0px;


    }
}
















/*@media screen and (max-width: 1750px){
    .hero-left {
        padding-left: 130px;
    }
    .hero-right {
        padding-left: 30px;
    }
}
@media screen and (max-width: 1520px){
    .hero-left {
        padding-left: 115px;
    }
    .hero-latest-btn {
        gap: 10px;
        width: 250px;
        height: 60px;
        margin-top: 20px;
        font-size: 18px;
    }
}
@media screen and (max-width: 1520px){
    #hroimg3 {
        display: none;
    }
    #hroimg2 {
        rotate: -5deg;
    }
}
@media screen and (max-width: 1327px) {
    .hero-left {
        padding-left: 100px;
    }
    .hero-left h2 {
        font-size: 22px;
    }
    .hero-left p {
        font-size: 60px;
    }
    #new-items {
        font-size: 23px;
    }
    .hero-latest-btn {
        gap: 10px;
        width: 250px;
        height: 60px;
        margin-top: 20px;
        font-size: 18px;
    }
    #hroimg1 {
        width: 245px;
        height: 350px;
    }
    #hroimg2 {
        width: 275px;
        height: 375px;
    }
    .hero-right{
        margin-top: 300px;
    }
}
@media screen and (max-width: 1024px) {
    .hero-left {
        padding-left: 40px;
    }
    .hero-left h2 {
        font-size: 20px;
    }
    .hero-left p {
        font-size: 50px;
    }
    .hero-right{
        margin: 0;
    }
    #new-items {
        font-size: 18px;
    }
    .hero-latest-btn {
        width: 220px;
        height: 50px;
        font-size: 16px;
    }
    #hroimg2 {
        width: 400px;
        height: 550px;
        margin: 0;
        rotate: 0deg;
    }
    #hroimg1 {
        display: none;
    }
    .hero-right-background {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10%;
    }

}
@media screen and (max-width: 770px) {
    .hero{
        flex-direction: column-reverse;
        height: 95vh;
    }
    .hero-left {
        height: 30vh;
    }
    #new-items {
        font-size: 20px;
    }
    .hero-left h2 {
        font-size: 20px;
    }
    .hero-left p {
        font-size: 60px;
    }
    .hero-right {
        box-shadow: inset 3px -3px 30px rgba(129, 129, 129, 0.836);
        width: 100vw;
        height: 100%;
        padding: 38px 0;
        background-color: #df979c;
    }
    .hero-latest-btn {
        width: 175px;
        height: 40px;
        font-size: 13px;
    }
    #hroimg2 {
        width: 200px;
        height: 250px;
    }
    #hroimg1 {
        width: 150px;
        height: 225px;
        display: flex;
    }
    #hroimg3 {
        width: 150px;
        height: 225px;
        display: flex;
    }
}
@media screen and (max-width:610px) {
    .hero{
        padding: 0px;
    }
    #hroimg3 {
        display: none;
    }
    #hroimg2 {
        rotate: -5deg;
    }
    .hero-left {
        padding-left: 40px;
    }
    #new-items {
        font-size: 20px;
    }
    .hero-left h2 {
        font-size: 20px;
    }
    .hero-left p {
        font-size: 60px;
    }
}

@media screen and (max-width: 500px) {
    .hero-left h2 {
        font-size: 14px;
    }
    .hero-left p {
        font-size: 55px;
    }
    .hero-latest-btn {
        width: 200px;
        height: 45px;
        font-size: 14px;
        font-weight: 700;
    }
}
@media screen and (max-width: 370px) {
    .hero{
        margin-bottom: 50px;
    }
    #hroimg1{
        display: none;
    }
    #hroimg2{
        rotate: 0deg;
        width: 100%;
        height: 100%;
        margin-top: 0px;
        border: none;
        border-radius: 0;
        z-index: -1;
    }
    .hero-left {
        padding-left: 20px;
    }
    .hero-right {
        padding: 0;
        margin-top: 50px;
        box-shadow: -3px 4px 10px 5px rgb(68 68 68);
        background-color: transparent;
    }
}









@media (height < 1000px) and (width < 770px > 370px) {
    .hero-left {
        margin: 100px 0;
        height: 100%;
    }
    .hero {
        height: 100%;
    }
}
@media (height < 1025px)  {
    .hero-right, .hero-left{
        margin-top: 0px;
        height: 100%;
    }
    .hero{
        height: 925px;
    }
}*/
